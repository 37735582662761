<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
        <div class="edit-header">
          <span>许可变更决定书</span>
          <div>
            <el-button type="primary" @click="print">打印</el-button>
            <el-button type="primary" v-show="!readOnly" @click="onSubmit"
            >保存
            </el-button>
          </div>
        </div>
      </FloatTitBtn>

      <div class="edit-container">
        <el-form
            :disabled="readOnly"
            ref="baseform"
            :label-position="labelPosition"
            :model="form"
            label-width="120px"
        >
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="文号:">
                <el-input v-model="form.WH"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="建设单位:">
                <el-input v-model="form.JSDW"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="原证发放编号:">
                <el-input v-model="form.YZSBH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="原证发放时间:">
                <el-input v-model="form.YZSFFSJ"></el-input>
                <!-- <el-date-picker
                  style="width: 100%"
                  v-model="form.YZSFFSJ"
                  placeholder="请选择接件日期"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="变更项目:">
                <el-input v-model="form.BGXM"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="变更内容:">
                <el-input
                    v-model="form.BGNR"
                    type="textarea"
                    :rows="3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="变更图件:">
                <el-input
                    v-model="form.BGTJ"
                    type="textarea"
                    :rows="3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="抄送:">
                <el-input v-model="form.CS"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发证日期:">
                <el-date-picker
                    style="width: 100%"
                    v-model="form.XKRQ"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    placeholder="请选择发证日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <div v-show="false">
      <div id="forms" style="line-height: 30px;padding-right: 100px;padding-left: 100px">
        <div style="font-size: 25px;display: flex;justify-content: center;margin-bottom: 10px;">慈溪市自然资源和规划局变更行政许可决定书
        </div>
        <div style="text-align: left;font-size: 14px;line-height: 20px">
          <div style="font-size:14px;text-align: center;">{{ form.WH }}</div>
          <p>{{ form.JSDW }}</p>
          <div style="width: 100%;font-size: 14px;display: flex;flex-wrap: nowrap;">
            <span style="text-indent: 2em;">你单位于</span>
            <div
                style="width: 23%;padding-left: 4px;word-wrap: break-word;box-sizing: border-box;text-align: center;line-height: 20px;padding-top: 0px">
              {{ form.YZSFFSJ }}
            </div>
            <span>申领了</span>
            <div
                style="width: 22.5%;box-sizing: border-box;word-wrap: break-word;padding-left: 4px;text-align: center;line-height: 20px;">
              {{ form.YZSBH }}
            </div>
            <span>《{{ zsTit }}》</span>
          </div>
          <div style=";font-size: 14px">,现根据你单位申请及《宁波市国土空间规划条例》第五十一条规定，我局决定变更该行政许可，具体变更内容如下：</div>
          <div style="font-size: 14px;min-height: 30px;padding-top: 10px">
            <!-- {{form.BGNR}} -->
            <div v-for="(v, i) in cllist" :key="i">
              <span>{{ v }}</span>
            </div>
          </div>
          <p style="text-indent: 2em;font-size: 14px">同步进行变更、补充的图片、附表有：</p>
          <div style=";font-size: 14px;min-height: 30px">
            <!-- {{form.BGTJ}} -->
            <div v-for="(v, i) in cllists" :key="i">
              <span>{{ v }}</span>
            </div>
          </div>
          <div style=";font-size: 14px">
            <div style="float: right;">
              <p> 慈溪市自然资源和规划局</p>
              <div style="text-indent: 2em">{{ (form.XKRQ + "")?.slice(0, 4) }}年{{
                  (form.XKRQ + "")?.slice(5, 7)
                }}月{{ (form.XKRQ + "")?.slice(8, 10) }}日
              </div>
            </div>
            <div style="padding-top:130px;">
              <p>抄送：{{ form.CS }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {ElMessage} from "element-plus";
import mgr from "@/services/security.js";
import FloatTitBtn from '../../components/FloatTitBtn.vue';
import print from "print-js";

export default {
  name: 'xkbg',
  components: {FloatTitBtn},
  data() {
    return {
      zsTit: '建设工程规划许可证',
      onFormData: '',
      readOnly: true,
      labelPosition: "left",
      keepForm: {
        Id: "",
        BJID: '',
        WH: "",
        JSDW: "",
        YZSBH: "",
        YZSFFSJ: "",
        BGXM: "",
        BGNR: "",
        BGTJ: "",
        CS: "",
        XKRQ: new Date(),
        JBR: "",
      },
      form: {
        Id: "",
        BJID: '',
        WH: "",
        JSDW: "",
        YZSBH: "",
        YZSFFSJ: "",
        BGXM: "",
        BGNR: "",
        BGTJ: "",
        CS: "",
        XKRQ: "",
        JBR: "",
      },
      jsdws: "",
      cllist: ['', '', ''],
      cllists: ['', '', '']
    };
  },
  methods: {
    print() {
      print({
        printable: "forms",
        type: "html",
        scanStyles: false,
        css: "/static/print-style.css",
        documentTitle: "",
      });
    },
    async onSubmit() {
      let me = this;
      if (me.checkform()) {
        await this.$ajax
            .post(me.$appConfig.apiUrls.ywxtApi + "/api/YW/AddorUpdatexkbg", me.form)
            .then(function (response) {
              if (response.data.IsSuccess) {
                me.form.Id = response.data.Data;
                me.keepForm = JSON.parse(JSON.stringify(me.form))
                if (me.form.BGNR == null || me.form.BGNR == undefined) {
                  me.form.BGNR = ''
                } else if (me.form.BGTJ == null || me.form.BGTJ == undefined) {
                  me.form.BGTJ = ''
                }
                me.cllist = me.form.BGNR.split('\n')
                me.cllists = me.form.BGTJ.split('\n')
                ElMessage({
                  type: "success",
                  message: "保存成功",
                });
              } else {
                ElMessage({
                  type: "info",
                  message: "保存失败!",
                });
                console.log(response.data.errorMessage);
              }
            });
      }
    },
    //转化json
    async switch() {
      let me = this;
      me.form.BJID = this.$route.query.instanceId;
      if (me.form.BJID) {
        await this.$ajax
            .get(this.$appConfig.apiUrls.ywxtApi + "/api/YW/Getlahz?Id=" + me.form.BJID)
            .then((response) => {
              const data = response.data.Data;
              if(data.SBLX=='bb66a209-6104-4a2f-979d-44ecf5e4396e'){
                me.zsTit = '乡村建设规划许可证'
              }
              this.jsdws = data.JSDW;
            })
        await this.$ajax
            .get(me.$appConfig.apiUrls.ywxtApi + "/api/YW/getxkbg?BJID=" + me.form.BJID)
            .then(function (response) {
              me.form = response.data.Data;
              if (response.data.IsSuccess) {
                if (me.form.JSDW == null) {
                  me.form.JSDW = me.jsdws
                } else {
                  me.form = response.data.Data;
                  if (response.data.Data.BGNR != null && response.data.Data.BGNR.trim() != '') {
                    me.cllist = me.form.BGNR.split('\n')
                  } else {
                    me.form.BGNR = ''
                  }
                  if (response.data.Data.BGTJ != null && response.data.Data.BGTJ.trim() != '') {
                    me.cllists = me.form.BGTJ.split('\n')
                  } else {
                    me.form.BGTJ = ''
                  }
                }

              } else {
                ElMessage({
                  type: "info",
                  message: "初始化表单失败",
                });
              }
            });

        const user = await mgr.getUser();
        if (this.form.JBR === user.profile.sub && this.$route.query.taskId) {
          this.readOnly = false;
        }
      }
    },
    checkform() {
      let b = true;
      // let f = this.form;
      // if (b) b = this.checkinput(f.JSDW, "建设单位");
      // if (b) b = this.checkinput(f.YZSBH, "原证发放编号");
      // if (b) b = this.checkinput(f.YZSFFSJ, "原证发放时间");
      // if (b) b = this.checkinput(f.BGXM, "变更项目");
      // if (b) b = this.checkinput(f.BGNR, "变更内容");
      // if (b) b = this.checkinput(f.BGTJ, "变更图件");
      // if (b) b = this.checkinput(f.CS, "抄送");
      return b;
    },
    checkinput(val, mess) {
      if (!val || val.length < 1) {
        ElMessage({
          type: "info",
          message: "请输入" + mess,
        });
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.switch();
  },
  created() {
    this.onFormData = this.$store.state.formData.JJRQ
    if (this.onFormData != null && this.onFormData != undefined && this.onFormData.length > 0) {
      this.onFormData = this.onFormData.slice(0, 10)
    } else {
      this.onFormData = ''
    }
  }
};
</script>

<style scoped>
.edit-header {

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-form {
  width: 100%;
  overflow: hidden;
}

.input-width {
  width: 100%;
}

.wrap-edit .edit-header {
  margin-bottom: 15px;
}
</style>
